import { useMemo } from "react"
import { useDispatch } from "react-redux"
import {
  actions,
  ApplyEditorChangesPayload,
  CreateLineItemFromTemplatePayload,
  CreateLineItemPayload,
  CreateLineItemWithImagePayload,
  CreateOrderPayload,
  CreateUploadPayload,
  DownloadProductTemplates,
  DownloadProductTemplateTags,
  DuplicateLineItemPayload,
  InsertPageSpreadToLineItemPayload,
  PlaceOrderPayload,
  RemoveIncompletePageSpreadsFromLineItemPayload,
  RemoveLineItemPayload,
  RemovePageSpreadFromLineItemPayload,
  SearchStorePayload,
  SetUpdateMissingImagesForLineItemDialogChoice,
  UpdateImageRegionImage,
  UpdateLineItemOrientationPayload,
  UpdateLineItemProduct,
  UpdateLineItemProductTemplate,
  UpdateLineItemProductTemplateVariant,
  UpdateMissingImagesForLineItem,
  UpdateStorePayload,
  UploadUriImagesPayload,
} from "~/redux/process"
import {
  AnalyticAction,
  AnalyticEventPayload,
} from "~/redux/saga/processAnalyticEvents"
import { UpdateOrderSummaryReasonType } from "~/redux/saga/processUpdateOrderSummary"

export const useProcessActions = () => {
  const dispatch = useDispatch()

  const processes = useMemo(
    () => ({
      searchStores: (payload: SearchStorePayload) => {
        dispatch(actions.searchStores(payload))
      },
      updateOrderSummary: ({
        reason,
        reasonType,
      }: {
        reason: string
        reasonType?: UpdateOrderSummaryReasonType
      }) => {
        dispatch(actions.updateOrderSummary({ reason, reasonType }))
      },
      placeOrder: (payload: PlaceOrderPayload) => {
        dispatch(actions.placeOrder(payload))
      },
      createOrder: (payload: CreateOrderPayload) => {
        dispatch(actions.createOrder(payload))
      },
      applyCouponCode: () => {
        dispatch(actions.applyCouponCode({}))
      },
      createUpload: (payload: CreateUploadPayload) => {
        dispatch(actions.createUpload(payload))
      },
      createLineItem: (payload: CreateLineItemPayload) => {
        dispatch(actions.createLineItem(payload))
      },
      createLineItemWithImage: (payload: CreateLineItemWithImagePayload) => {
        dispatch(actions.createLineItemWithImage(payload))
      },
      createLineItemFromTemplate: (
        payload: CreateLineItemFromTemplatePayload
      ) => {
        dispatch(actions.createLineItemFromTemplate(payload))
      },
      duplicateLineItem: (payload: DuplicateLineItemPayload) => {
        dispatch(actions.duplicateLineItem(payload))
      },
      updateLineItemProduct: (payload: UpdateLineItemProduct) => {
        dispatch(actions.updateLineItemProduct(payload))
      },
      updateLineItemProductTemplate: (
        payload: UpdateLineItemProductTemplate
      ) => {
        dispatch(actions.updateLineItemProductTemplate(payload))
      },
      updateLineItemProductTemplateVariant: (
        payload: UpdateLineItemProductTemplateVariant
      ) => {
        dispatch(actions.updateLineItemProductTemplateVariant(payload))
      },
      updateLineItemOrientation: (
        payload: UpdateLineItemOrientationPayload
      ) => {
        dispatch(actions.updateLineItemOrientation(payload))
      },
      removeLineItem: (payload: RemoveLineItemPayload) => {
        dispatch(actions.removeLineItem(payload))
      },
      updateImageRegionImage: (payload: UpdateImageRegionImage) => {
        dispatch(actions.updateImageRegionImage(payload))
      },
      updateMissingImagesForLineItem: (
        payload: UpdateMissingImagesForLineItem
      ) => {
        dispatch(actions.updateMissingImagesForLineItem(payload))
      },
      setUpdateMissingImagesDialogChoice: (
        payload: SetUpdateMissingImagesForLineItemDialogChoice
      ) => {
        dispatch(actions.setUpdateMissingImagesDialogChoice(payload))
      },
      downloadProductTemplateTags: (payload: DownloadProductTemplateTags) => {
        dispatch(actions.downloadProductTemplateTags(payload))
      },
      downloadProductTemplates: (payload: DownloadProductTemplates) => {
        dispatch(actions.downloadProductTemplates(payload))
      },
      updateStore: (payload: UpdateStorePayload) => {
        dispatch(actions.updateStore(payload))
      },
      applyEditorChanges: (payload: ApplyEditorChangesPayload) => {
        dispatch(actions.applyEditorChanges(payload))
      },
      uploadUriImages: (payload: UploadUriImagesPayload) => {
        dispatch(actions.uploadUriImages(payload))
      },
      fireAnalyticEvent: (payload: AnalyticEventPayload) => {
        dispatch(AnalyticAction(payload))
      },
      insertPageSpreadToLineItemAfter: (
        payload: InsertPageSpreadToLineItemPayload
      ) => {
        dispatch(actions.insertPageSpreadToLineItemAfter(payload))
      },
      insertPageSpreadToLineItemBefore: (
        payload: InsertPageSpreadToLineItemPayload
      ) => {
        dispatch(actions.insertPageSpreadToLineItemBefore(payload))
      },
      removePageSpreadFromLineItem: (
        payload: RemovePageSpreadFromLineItemPayload
      ) => {
        dispatch(actions.removePageSpreadFromLineItem(payload))
      },
      removeIncompletePageSpreadsFromLineItem: (
        payload: RemoveIncompletePageSpreadsFromLineItemPayload
      ) => {
        dispatch(actions.removeIncompletePageSpreadsFromLineItem(payload))
      },
    }),
    [dispatch]
  )

  return processes
}
